import { Controller } from 'stimulus'
import { showElement, hideElement } from '../utils/html_element_helpers'

export default class extends Controller {
  static values = { deadlineId: Number, ticketId: Number }
  static targets = [
    'responsibleSelect',
    'alertDeadline',
    'deadlineCheckbox',
    'dateInput',
    'dateGroup',
    'itemList',
  ]

  connect() {
    this.completedOn = null
    this.dueOn = null
  }

  toggleLocationInput() {
    document.getElementById('ticket-items-list').innerHTML = ''
  }

  toggleDeadlineCheckbox(_event) {
    if (this.deadlineCheckboxTarget.checked) {
      this.dateInputTarget.value = this.dateInputTarget.dataset.recommendation
      this.dateGroupTarget.children[1].focus()

      hideElement(this.alertDeadlineTarget)
    } else {
      this.dateInputTarget.value = ''

      showElement(this.alertDeadlineTarget)
    }
  }

  updateDeadlines(event) {
    const date = this.formatDate(event.detail.dates[0])
    const name = event.detail.name
    if (!date) return
    if (name === 'ticket[due_on]' && date !== this.dueOn) {
      this.dueOn = date
      this.requestUpdate()
    } else if (name === 'ticket[completed_on]' && date !== this.completedOn) {
      this.completedOn = date
      this.requestUpdate()
    }
  }

  requestUpdate() {
    const ids = this.currentIds().filter(Boolean)
    const query = new URLSearchParams()
    ids.forEach((id) => query.append('ids[]', id))
    query.append('ticket_id', this.ticketIdValue)
    query.append('deadline_id', this.deadlineIdValue)
    query.append('due_on', this.dueOn)
    query.append('completed_on', this.completedOn)
    const href = `/items_deadlines?${query.toString()}`
    fetch(href, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }

  formatDate(date) {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    return `${year}-${month}-${day}`
  }

  currentIds() {
    const ids = []
    document.querySelectorAll('[data-item-id]').forEach((row) => {
      ids.push(parseInt(row.dataset.itemId))
    })

    return ids
  }
}
