import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="submit-form"
export default class extends Controller {
  static targets = ['query', 'sortSelector']

  static values = {
    attachmentTag: String,
    resourceId: String,
    resourceType: String,
    searchbar: Boolean,
    turboFrameName: String,
    uploadUrl: String,
    url: String,
  }

  connect() {
    // Focus keyboard on the query input when the page loads:
    if (this.queryTarget) {
      this.queryTarget.focus()
      // Move cursor to the end of the input field:
      this.queryTarget.setSelectionRange(this.queryTarget.value.length, this.queryTarget.value.length)
    }
  }

  update(_event) {
    this.sendRequest()
  }

  filterUpdate(_event) {
    this.sendRequest()
  }

  sendRequest() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.queryTarget.requestSubmit()
    }, 400)
  }
  checkInput(_event) {
    this.queryTarget.requestSubmit()
  }

  updateGet(_event) {
    this.sendGetRequest()
  }

  sendGetRequest() {
    const query = this.queryTarget.value
    const params = new URLSearchParams({
      attachment_tag: this.attachmentTagValue,
      query: query,
      resource_id: this.resourceIdValue,
      resource_type: this.resourceTypeValue,
      searchbar: this.searchbarValue,
      sort_by: this.sortSelectorTarget.value,
      turbo_frame_name: this.turboFrameNameValue,
      upload_url: this.uploadUrlValue,
    })
    const href = `${this.urlValue}?${params.toString()}`

    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      fetch(href, {
        method: 'GET',
        headers: {
          'Accept': 'text/vnd.turbo-stream.html',
        },
      })
        .then((r) => r.text())
        .then((html) => Turbo.renderStreamMessage(html))
    }, 400)
  }
}
