export class ScanProcessing {
  // Work --> Move logic to evaluate url type into backend.

  static async processScanAndQueryDb(scanResult, position, processMethod, force_active = false) {
    const forwardUrl = '/scan'
    if (this.urlFromAllowedHostNames(scanResult.text)) {
      const urlEval = this.urlType(scanResult.text)
      if (urlEval == 'null') {
        return this.failureResponseObject('not_valid')
      } else {
        const extraParams = { force_active: force_active, process_method: processMethod }
        return await this.ajaxPostDataToUrl(
          urlEval.barcode, scanResult.symbology, urlEval.type, forwardUrl, extraParams, position,
        )
      }
    } else {
      return await this.ajaxPostDataToUrl(scanResult.text, scanResult.symbology,
        'barcode', forwardUrl, { force_active: force_active, process_method: processMethod }, position)
    }
  }

  static async submitToUrl(scanResult, forwardUrl, additionalParams = {}, position) {
    if (typeof forwardUrl == 'undefined') {
      return this.failureResponseObject('missing_forwardUrl')
    }
    if (this.urlFromAllowedHostNames(scanResult.text)) {
      const urlEval = this.urlType(scanResult.text)
      if (urlEval == 'null') {
        return this.failureResponseObject('not_valid')
      } else {
        return await this.ajaxPostDataToUrl(urlEval.barcode, scanResult.symbology,
          urlEval.type, forwardUrl, additionalParams, position)
      }
    } else {
      return await this.ajaxPostDataToUrl(scanResult.text, scanResult.symbology,
        'barcode', forwardUrl, additionalParams, position)
    }
  }

  static urlFromAllowedHostNames(scanString) {
    return scanString && (scanString.includes('timly.') || scanString.includes('localhost:3000'))
  }

  static checkIsUrl(scanString) {
    return scanString && (scanString.includes('http') || scanString.includes('www') || scanString.includes('localhost'))
  }

  // Function to evaluate url, wheather it contains an item id, a barcde or a token
  static urlType(url) {
    let myRegexp
    let barcodeType
    if (url.includes('/items/')) {
      // Catch Scan results whitch are a direct link to the item --> e.g. https://app.timly.com/items/123
      myRegexp = /\/items\/(.*)/
      barcodeType = 'item_id'
    } else if (url.includes('/bc/')) {
      // Query for barcode values stored the BarcodeModel. These barcodes can be connected to any item or container
      // https://app.timly.com/bc/:barcode_number
      // barcode_number can be any string, but most of the time a 6-digit number
      myRegexp = /\/bc\/(.*)/
      barcodeType = 'barcode'
    } else if (url.includes('/token/')) {
      // This queries for Tokens stored on the SingleAccessTokenModel.
      // Tokens can be created on an item, so the one item can be accessed without a login
      // https://app.timly.com/token/:token_value
      myRegexp = /\/token\/(.*)/
      barcodeType = 'token'
    } else if (url.includes('/tk/')) {
      // This queries for Tokens stored on the SingleAccessTokenModel.
      // Tokens can be created on an item, so the one item can be accessed without a login
      // https://app.timly.com/token/:token_value
      myRegexp = /\/tk\/(.*)/
      barcodeType = 'token'
    }
    const match = myRegexp.exec(url)

    if (match == null) {
      return null
    } else {
      return { type: barcodeType, barcode: match[1].split('?')[0] }
    }
  }

  static async ajaxPostDataToUrl(scanResultText, barcodeSymbology, type, forwardUrl, additionalParams = {}, position) {
    // merge params with additionalParams --> spread operator
    const data = { ...{
      scan_result: scanResultText,
      barcode_symbology: barcodeSymbology,
      type: type,
      authenticity_token: this.authenticityToken,
    },
    ...additionalParams,
    }

    // add the gps data, if t is available
    if (typeof position != 'undefined') {
      data.gps_position = {
        longitude: position.longitude,
        latitude: position.latitude,
        accuracy: position.accuracy,
      }
    }

    const response = await fetch(forwardUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.authenticityToken,
      },
      body: JSON.stringify(data),
    })
    const jsonResponse = await response.json()
    if (!response.ok) {
      return this.failureResponseObject(
        jsonResponse.error, jsonResponse.barcode, jsonResponse.datToken, jsonResponse.objName,
      )
    } else {
      jsonResponse.status = 'ok'
      return jsonResponse
    }
  }

  static get authenticityToken() {
    return document.querySelector('meta[name=\'csrf-token\']').getAttribute('content')
  }

  static failureResponseObject(typeOfFailure, barcode, datToken, objName) {
    const responseObject = { status: 'failed', error: typeOfFailure }
    if (typeof(barcode) != 'undefined') {
      responseObject.barcode = barcode
    }
    if (typeof(datToken) != 'undefined') {
      responseObject.datToken = datToken
    }
    if (typeof(objName) != 'undefined') {
      responseObject.objName = objName
    }
    return responseObject
  }
};
