import { Controller } from '@hotwired/stimulus'
import { setCookie } from '../utils/cookies_helper'

export default class extends Controller {
  static targets = [
    'link',
    'secondaryMenu',
    'userMenu',
    'userLink',
    'mainMenu',
    'burger',
    'navWrapper',
    'newDesignLink',
    'newDesignMenu',
    'logoLink',
    'logoMenu',
  ]

  connect() {}

  clickOutside(event) {
    this.clickOutsideMenu(event)
    this.clickOutsideNewDesign(event)
  }

  clickOutsideMenu(event) {
    const clickedOutsideLink = !this.userLinkTarget.contains(event.target)
    const clickedOutsideMenu = !this.userMenuTarget.contains(event.target)

    if (clickedOutsideLink && clickedOutsideMenu) {
      this.userLinkTarget.classList.remove('active')
      this.userMenuTarget.classList.add('tw-hidden')
    }
  }

  clickOutsideNewDesign(event) {
    const clickedOutsideLink = !this.newDesignLinkTarget.contains(event.target)
    const clickedOutsideMenu = !this.newDesignMenuTarget.contains(event.target)

    if (clickedOutsideLink && clickedOutsideMenu) {
      this.newDesignLinkTarget.classList.remove('active')
      this.newDesignMenuTarget.classList.add('tw-hidden')
    }
  }

  showMainMenu() {
    if (this.burgerTarget.classList.contains('active')) {
      // Hide all secondary menu's
      this.secondaryMenuTargets.forEach((menu) => {
        menu.classList.remove('active')
      })
    }
    this.mainMenuTarget.classList.toggle('tw-hidden')
    this.burgerTarget.classList.toggle('active')
  }

  showSecondaryMenu(event) {
    event.preventDefault()

    // Show the clicked secondary menu
    const clickedLink = event.currentTarget
    const menuId = clickedLink.dataset.menuId
    const targetSecondaryMenu = this.secondaryMenuTargets.find((menu) =>
      menu.dataset.menuId === menuId,
    )

    setCookie('nav_second_level', menuId, 365)

    const link = targetSecondaryMenu.getElementsByClassName('secondary-menu-link')[0]
    if (link) { link.click() }

    if (clickedLink.classList.contains('active')) {
      clickedLink.classList.remove('active')
      targetSecondaryMenu.classList.remove('active')
      this.navWrapperTarget.classList.remove('active')
    } else {
      // Hide all secondary menu's
      this.secondaryMenuTargets.forEach((menu) => {
        menu.classList.remove('active')
      })

      // Remove active class from all menus
      this.linkTargets.forEach((link) => {
        link.classList.remove('active')
      })

      // Add active class to clicked menu and show secondary menu
      clickedLink.classList.add('active')
      targetSecondaryMenu.classList.add('active')
      this.navWrapperTarget.classList.add('active')
    }
  }

  closeSecondaryMenu() {
    // Hide all secondary menu's
    this.secondaryMenuTargets.forEach((menu) => {
      menu.classList.remove('active')
      this.navWrapperTarget.classList.remove('active')
    })
    setCookie('nav_second_level', '', 0)
  }

  toggleUserMenu(event) {
    event.preventDefault()
    this.newDesignLinkTarget.classList.remove('active')
    this.newDesignMenuTarget.classList.add('tw-hidden')
    this.userLinkTarget.classList.toggle('active')
    this.userMenuTarget.classList.toggle('tw-hidden')
  }

  toggleNewDesignMenu(event) {
    event.preventDefault()
    this.userLinkTarget.classList.remove('active')
    this.userMenuTarget.classList.add('tw-hidden')
    this.newDesignLinkTarget.classList.toggle('active')
    this.newDesignMenuTarget.classList.toggle('tw-hidden')
  }

  toggleLogoMenu(event) {
    event.preventDefault()
    this.logoLinkTarget.classList.toggle('active')
    this.logoMenuTarget.classList.toggle('tw-hidden')
  }

  setLocation(event) {
    const locationId = event.detail.selectedOptions[0].value
    fetch('/set_location_id/' + locationId, { method: 'GET' })
      .then((response) => window.location.reload())
  }
}
