import { Controller } from 'stimulus'
import { map } from 'lodash'
import { fetchOptionValue } from '../../utils/html_element_helpers'
import { SearchInitializer } from '../../library/index_search/search_initializer'
import { SortService } from '../../library/index_search/sort_service'
import { SearchSelectService } from '../../library/index_search/search_select_service'

const TIMEOUT = 600

export default class extends Controller {
  static targets = [
    'searchFilters',
    'searchForm',
    'headerButtons',
    'inputQueryField',
    'offsidebar',
    'subMenuFilters',
    'subMenuFields',
    'subMenuTabs',
    'subMenuFiltersContainer',
    'subMenuFieldsContainer',
    'subMenuTabsContainer',
    'columns',
    'currentViewConfigId',
    'tabAction',
    'deleteConfig',
    'updateIdConfig',
    'updateNameConfig',
    'currentFilterTab',
    'nameConfig',
    'columnField',
    'configNameInput',
    'pageNumber',
    'perPage',
    'subMenuSkills',
    'subMenuSkillsContainer',
    'startDate',
    'endDate',
    'sortTabPosition',
    'sortTabId',
    'missingSort',
    'expiringSkillsCheckbox',
    'period',
    'sharedViewCreateLink',
    'myTeam',
    'searchColumnWrapper',
    'columnFilterInput',
    'updateTableHeaderFlag',
    'subMenuFiltersContainerFlag',
    'selectAllRows',
    'selectRow',
    'selectedActions',
    'selectedItemsCount',
    'batchDeleteModal',
    'batchDeleteText',
    'batchDeleteConfirmationModal',
    'batchDeleteConfirmationText',
    'confirmationDeleteInput',
    'batchCopyModal',
    'batchCopyText',
    'massEditModal',
    'editBatchForm',
    'tabsColumns',
    'defaultColumns',
  ]

  static values = {
    searchMethod: String,
    deleteText: String,
    batchEditUrl: String,
  }

  initialize() {
    this.searchInitObject = new SearchInitializer(this, {})
    this.sortService = new SortService(this, {})
    this.searchSelectService = new SearchSelectService(this, {})
  }

  connect() {
    this.searchInitObject.init()
    if (this.hasTabActionTarget) {
      this.tabActionTarget.value = ''
    }
    this.moveInputCursor()
    this.updateParams()
    this.openFilterTab()
  }

  toggleColumnFilterOpen(e) {
    e.currentTarget.classList.toggle('tw-bg-blue-50')
    if (e.currentTarget.classList.contains('tw-bg-blue-50')) {
      const div = document.querySelector('div[data-controller="filter"]')
      if (div) {
        div.remove()
      }
      const elements = document.querySelectorAll('.indicate_active_filter')

      elements.forEach((el) => {
        // Skip the current target
        if (el !== e.currentTarget) {
          el.classList.remove('tw-bg-blue-50') // Remove the 'tw-bg-blue-50' class
        }
      })
    }
  }

  selectAllToggle(e) {
    this.searchSelectService.selectAllToggle(e)
  }

  selectRowToggle(e) {
    this.searchSelectService.selectRowToggle(e)
  }

  batchDeleteShowModal(e) {
    this.searchSelectService.batchDeleteShowModal(e)
  }

  batchDeleteHideModal(e) {
    this.searchSelectService.batchDeleteHideModal(e)
  }

  batchCopyHideModal(e) {
    this.searchSelectService.batchCopyHideModal(e)
  }

  batchCopyShowModal(e) {
    this.searchSelectService.batchCopyShowModal(e)
  }

  batchCopyItems(e) {
    this.searchSelectService.batchCopyItems(e)
  }

  batchDeleteConfirmationModalShow(e) {
    this.searchSelectService.batchDeleteConfirmationModalShow(e)
  }

  batchDeleteConfirmationModalHide(e) {
    this.searchSelectService.batchDeleteConfirmationModalHide(e)
  }

  batchDeleteItems(e) {
    this.searchSelectService.batchDeleteItems(e)
  }

  massEditShowModal(e) {
    this.searchSelectService.massEditShowModal(e)
  }

  massEditCloseModal(e) {
    this.searchSelectService.massEditCloseModal(e)
  }

  massEditCloseModalOutside(e) {
    this.searchSelectService.massEditCloseModalOutside(e)
  }

  massUpdate(e) {
    this.searchSelectService.massUpdate(e)
  }

  updateColumnFilter(e) {
    const options = e.detail
    if (!this.columnFilters) {
      this.resetColumnFilters()
    }
    if (options.fieldType === 'multi') {
      if (!this.columnFilters[options.fieldName]) {
        this.columnFilters[options.fieldName] = { value: [], type: options.fieldType }
      }
      const index = this.columnFilters[options.fieldName].value.indexOf(options.value)
      if (index > -1) {
        this.columnFilters[options.fieldName].value.splice(index, 1)
      } else {
        this.columnFilters[options.fieldName].value.push(options.value)
      }
    } else {
      this.columnFilters[options.fieldName] = { value: options.value, type: options.fieldType }
    }
    this.search()
  }

  updateSearch(e) {
    e.preventDefault()
    this.updateParams(e.detail)
    this.searchInitObject.initColumns()
    this.searchFormTarget.classList.remove('whirl', 'double-up')
    if (this.hasSubMenuFieldsContainerTarget) {
      this.subMenuFieldsContainerTarget.classList.remove('whirl', 'double-up')
    }
    this.setUpdateTableHeaderFlag(true)
    this.updateSubMenuFiltersContainerFlag(false)
    this.setSelectedColumns()
    if (this.hasTabActionTarget) {
      this.tabActionTarget.value = ''
    }
  }

  changeSearchColumnInput(e) {
    this.setUpdateTableHeaderFlag(false)
  }

  resetFilters() {
    this.filters = {}
    this.q = ''
    this.sort = {}
    this.columns = {}
    this.myTeam = ''
    this.columnFilters = {}
    if (this.hasPerPageTarget) {
      this.perPageTarget.value = ''
    }
    this.inputQueryFieldTarget.value = ''
  }

  openFilterTab() {
    if (
      this.hasCurrentFilterTabTarget &&
      this.currentFilterTabTarget.value &&
      this.currentFilterTabTarget.value != ''
    ) {
      this.offsidebarTarget.classList.toggle('offsidebar-open')
      document.body.classList.toggle('offsidebar-open')
      document.body.classList.add('offsidebar-animation')
      setTimeout(() => {
        document.body.classList.remove('offsidebar-animation')
      }, 500)
      this.switchSearchFiltersTab('', this.currentFilterTabTarget.value)
    }
  }

  updateParams(obj) {
    let path = window.location.href.split('?')[0]
    if (!obj) {
      obj = window.location.search
    }
    const queryObj = new URLSearchParams(obj)
    if (
      queryObj.get('tab_action') === 'change_tab' ||
      queryObj.get('tab_action') === 'reset'
    ) {
      queryObj.delete('search')
      queryObj.delete('columns')
      queryObj.delete('per_page')
    } else if (queryObj.get('tab_action') === 'reorder') {
      queryObj.delete('position')
      queryObj.delete('tab_id')
    }
    queryObj.delete('tab_action')
    queryObj.delete('delete_config_id')
    queryObj.delete('config_name')
    queryObj.delete('current_filter_tab')
    queryObj.delete('update_config_id')
    queryObj.delete('update_config_name')
    queryObj.toString()
    path += '?' + queryObj.toString()
    window.history.pushState({ url: path }, document.title, path)
  }

  moveInputCursor() {
    if (!this.inputQueryFieldTarget) {
      return
    }
    const end = this.inputQueryFieldTarget.value.length
    this.inputQueryFieldTarget.setSelectionRange(end, end)
    this.inputQueryFieldTarget.focus()
  }

  copyTabLink(e) {
    e.preventDefault()
    const currentConfigId = e.currentTarget.dataset['value']
    const name = e.currentTarget.dataset['name']
    const target = e.target
    const text = this.escapeString(
      JSON.stringify({
        config_id: currentConfigId,
        name: name,
        user: window.USER_NAME,
      }),
    )
    const url =
      location.protocol + '//' + location.host + '/items?share_view=' + text
    if (navigator.clipboard) {
      navigator.clipboard.writeText(url)
      $(target).tooltip('show')
      setTimeout(() => {
        $(target).tooltip('dispose')
      }, 300)
    } else {
      navigator.permissions
        .query({ name: 'clipboard-write' })
        .then((result) => {
          if (result.state == 'granted' || result.state == 'prompt') {
            navigator.clipboard.writeText(url)
            $(target).tooltip('show')
            setTimeout(() => {
              $(target).tooltip('dispose')
            }, 300)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  changeTab(e) {
    e.preventDefault()
    this.resetFilters()
    this.resetColumnFilters()
    const currentConfigId = e.currentTarget.dataset['value']
    this.setTabAction(e)
    this.currentViewConfigIdTarget.value = currentConfigId
    if (this.hasMyTeamTarget) {
      this.myTeamTarget.value = false
      this.myTeam = ''
    }
    this.controllerStickyOptions = {}
    this.search()
  }

  changeToMyTeam(e) {
    e.preventDefault()
    this.resetFilters()
    this.setTabAction(e)
    this.myTeamTarget.value = true
    this.myTeam = true
    this.currentViewConfigIdTarget.value = ''
    this.controllerStickyOptions = {}
    this.search()
  }

  setActiveCategories() {
    this.setActiveObjects('category')
  }

  setActiveSkills() {
    this.setActiveObjects('skill')
  }

  setActiveSkillCategory() {
    this.setActiveObjects('skill_category')
  }

  setActiveObjects(dataType) {
    ;((this.filters[dataType] || {})['value'] || []).forEach(function(value) {
      document
        .querySelectorAll(
          `[data-value="${value}"][data-data-type="${dataType}"]`,
        )
        .forEach(function(el) {
          if (!el.checked) {
            el.checked = true
          }
        })
    })
  }

  openAsyncNode(e) {
    this.setActiveCategories()
    this.setActiveSkillCategory()
    this.setActiveSkills()
  }

  selectAsyncNode(e) {
    const columnsName = e.detail.value.columnName
    console.log(e.detail)
    this.applyFilter(columnsName, 'is', e.detail.value.value)
  }

  resetCategories(e) {
    e.preventDefault()
    this.resetCategoryFilter()
    this.applyFilter('root_category', 'is', null)
  }

  resetCategoryFilter() {
    this.applyFilter('category', 'is', null)
  }

  resetCheckoutableFilter() {
    this.applyFilter('checkoutable_id', 'is', null)
    this.applyFilter('checkoutable_type', 'is', null)
  }

  toggleColumn(e) {
    e.preventDefault()
    const currentTarget = e.currentTarget
    const columnData = currentTarget.dataset
    const column = columnData['value']
    const order = columnData['order']
    this.controllerStickyOptions = {}
    this.applyColumn({ name: column, order: order })

    clearTimeout(this.columnTimeout)
    this.columnTimeout = setTimeout(() => {
      this.search()
    }, 1000)
  }

  selectAllColumns(e) {
    e.preventDefault()
    if (e.currentTarget.dataset.value == 'all') {
      this.columns = this.desirialize(
        this.tabsColumnsTarget.dataset.tabsColumns,
      )
    } else {
      this.columns = this.desirialize(
        this.defaultColumnsTarget.dataset.defaultColumns,
      )
    }
    this.search()
  }

  updateColumnsSort(e) {
    this.subMenuFieldsContainerTarget.classList.add('whirl', 'double-up')
    this.sortService.updateColumnsSort(e)
  }

  applyColumn(column) {
    if (!this.columns[column['name']]) {
      this.columns[column['name']] = column['order']
    } else {
      delete this.columns[column['name']]
    }
  }

  changeInputField(e) {
    this.q = e.target.value
    clearTimeout(this.inputTimeout)
    this.inputTimeout = setTimeout(() => {
      this.search()
    }, TIMEOUT)
  }

  applySort(e) {
    this.sortService.applySort(e)
  }

  changePerPage(event) {
    event.preventDefault()
    if (this.hasPerPageTarget) {
      this.perPageTarget.value = event.target.value
    }
    this.performSearch()
  }

  changePage(event) {
    event.preventDefault()
    const url = new URL(
      'http://www.test.com' + event.target.getAttribute('href'),
    )
    const pages = url.searchParams.getAll('page')
    let pageNumber = 1
    if (pages) {
      pageNumber = pages[0]
    }
    if (this.hasPageNumberTarget) {
      this.pageNumberTarget.value = pageNumber
    }
    this.searchFormTarget.requestSubmit()
  }

  selectRootCategories(e) {
    const attrs = e.target.dataset
    if (!e.target.checked) {
      const checkboxes = document.querySelectorAll(`input[type="checkbox"][data-parent-category-id="${attrs['value']}"]`)
      const filter = this.filters['category']
      checkboxes.forEach((checkbox) => {
        const value = checkbox.getAttribute('data-value')
        filter['value'] = filter['value'].filter(
          function(arValue, index, arr) {
            return arValue !== value
          },
        )
        if (filter['value'].length === 0) {
          delete this.filters[name]
        }
      })
      const rootCategory = this.filters['root_category']
      rootCategory['value'] = rootCategory['value'].filter(
        function(arValue, index, arr) {
          return arValue !== attrs['value']
        },
      )
      this.search()
    } else {
      this.applyFilter('root_category', 'is', attrs['value'])
    }
  }

  selectStatus(e) {
    const attrs = e.target.dataset
    this.applyFilter('status', 'is', attrs['value'])
  }

  selectReminder(e) {
    const attrs = e.target.dataset
    this.applyFilter('reminder', 'is', attrs['value'])
  }

  selectTicketStatus(e) {
    const attrs = e.target.dataset
    this.applyFilter('ticket_status_id', 'is', attrs['value'])
  }

  selectSchedulingStatus(e) {
    const attrs = e.target.dataset
    this.applyFilter('scheduling_status', 'is', attrs['value'])
  }

  selectCheckoutable(e) {
    const checkoutableId = e.target.value
    // eslint-disable-next-line
    let checkoutableType = document.getElementById('checkoutable-type').value
    this.resetCheckoutableFilter()
    this.applyFilter('checkoutable_type', 'is', checkoutableType)
    this.applyFilter('checkoutable_id', 'is', checkoutableId)
  }

  selectUserType(e) {
    const attrs = e.target.dataset
    this.applyFilter('user_type', 'is', attrs['value'])
  }

  selectResourceableType(e) {
    const attrs = e.target.dataset
    this.applyFilter('resourceable_type', 'is', attrs['value'])
  }

  selectTicketType(event) {
    if (event.detail.node !== 'ticket_type[id]') {
      return
    }
    const selectedTicketTypeId = map(
      event.detail.selectedOptions,
      fetchOptionValue,
    )
    if (!selectedTicketTypeId) {
      return
    }
    this.applyFilter('ticket_type_id', 'is', null)
    if (
      selectedTicketTypeId.length > 0 &&
      !selectedTicketTypeId.includes(true)
    ) {
      this.applyFilter('ticket_type_id', 'is', null)
      this.applyFilter('ticket_type_id', 'is', selectedTicketTypeId.pop())
    }
  }

  selectIndustry(e) {
    const attrs = e.target.dataset
    this.applyFilter('industry', 'is', attrs['value'])
  }

  selectCreatedBy(e) {
    const attrs = e.target.dataset
    this.applyFilter('created_by', 'is', attrs['value'])
  }

  selectModule(e) {
    const attrs = e.target.dataset
    this.applyFilter(attrs['attr'], 'is', attrs['value'])
  }

  selectItemStatus(e) {
    const attrs = e.target.dataset
    this.applyFilter('item_type', 'is', attrs['value'])
  }

  selectItemAggregation(e) {
    const attrs = e.target.dataset
    if (!e.currentTarget.checked) {
      this.applyFilter('items_w_blueprint_items_aggregated', 'is_not', null)
      return
    }
    this.applyFilter('items_w_blueprint_items_aggregated', 'is_not', null)
    this.applyFilter(
      'items_w_blueprint_items_aggregated',
      'is_not',
      attrs['value'],
    )
  }

  selectUncategorized(e) {
    const attrs = e.target.dataset
    this.applyFilter(attrs['name'], 'is_not', attrs['value'])
  }

  selectActive(e) {
    const attrs = e.target.dataset
    this.applyFilter('active', 'is', attrs['value'])
  }

  removeFilter(event) {
    const dataset = event.target.dataset
    if (dataset.operation == '' || !dataset.operation) {
      if (dataset.filterName == 'status') {
        this.applyFilter(dataset.filterName, dataset.operation, dataset.value)
      }
      return this.removeColumnSearchFilter(dataset)
    }
    if (dataset.filterName == 'responsible_user_id') {
      const elementId =
        document.getElementsByName('user[id]')[0] ||
        document.getElementsByName('user[id][]')[0]
      this.removeMultiSelectValues(dataset.filterName, elementId, dataset.value)
    } else {
      this.applyFilter(dataset.filterName, dataset.operation, dataset.value)
    }
  }

  removeColumnSearchFilter(dataset) {
    const filterName = dataset.filterName
    if (this.columnFilters[filterName] && ['multi', 'category', 'multi-array'].includes(this.columnFilters[filterName].type)) {
      this.columnFilters[filterName].value = this.columnFilters[filterName].value.filter(
        function(arValue, index, arr) {
          return arValue !== dataset.value
        },
      )
      if (this.columnFilters[filterName].value.length === 0) {
        delete this.columnFilters[filterName]
      }
    } else {
      delete this.columnFilters[filterName]
    }
    this.search()
  }

  removeCheckoutableFilter(e) {
    const dataset = e.target.dataset
    this.applyFilter('checkoutable_type', 'is', null)
    this.applyFilter(dataset.filterName, dataset.operation, dataset.value)
  }

  selectLocation(e) {
    const attrs = e.target.dataset
    this.applyFilter('location', 'is', attrs['value'])
  }

  selectLansweeperFilter(e) {
    const attrs = e.target.dataset
    this.applyFilter(attrs['name'], 'is', attrs['value'])
  }

  selectItemStockStatus(e) {
    const attrs = e.target.dataset
    this.applyFilter('item_stock_status', 'is', attrs['value'])
  }

  selectWithoutValue(e) {
    const attrs = e.target.dataset
    this.applyFilter(attrs['attr'], 'is_not', attrs['value'])
  }

  selectRootCategory(e) {
    e.preventDefault()
    const dataset = e.target.dataset
    if (!dataset.value) {
      return
    }
    const current = e.currentTarget.classList.contains('selected')

    if (dataset.current) {
      e.currentTarget.classList.toggle('badge')
      e.currentTarget.classList.toggle('bg-primary')
      e.currentTarget.classList.toggle('selected')
    }

    this.resetCategoryFilter()
    this.applyFilter('root_category', 'is', null)
    if (!current) {
      this.applyFilter('root_category', 'is', dataset.value)
    }
  }
  selectPeriod(e) {
    e.preventDefault()
    this.applyFilter('period', 'is', null)
    this.applyFilter('reduce_view', 'is', null)
    if (this.expiringSkillsCheckboxTarget.checked) {
      this.applyFilter('reduce_view', 'is', e.currentTarget.value)
    }
    this.applyFilter('period', 'is', e.currentTarget.value)
  }

  selectReducedView(e) {
    e.preventDefault()
    if (e.currentTarget.checked) {
      this.applyFilter('reduce_view', 'is', this.periodTarget.value)
    } else {
      this.applyFilter('reduce_view', 'is', null)
    }
  }

  selectDeadlineTemplate(e) {
    e.preventDefault()
    const selectedDeadlineTemplateIds = map(
      e.detail.selectedOptions,
      fetchOptionValue,
    )
    if (!selectedDeadlineTemplateIds) {
      return
    }
    this.applyFilter('deadline_template_ids', 'is', null)
    if (
      selectedDeadlineTemplateIds.length > 0 &&
      !selectedDeadlineTemplateIds.includes(true)
    ) {
      this.applyFilter(
        'deadline_template_ids',
        'is',
        selectedDeadlineTemplateIds,
      )
    }
  }

  removeDeadlineTemplate(e) {
    const templateableId = document.getElementById('deadline_template_id')
    const dataset = e.target.dataset
    this.removeMultiSelectValues(
      'deadline_template_ids',
      templateableId,
      dataset.value,
    )
  }

  selectAssignable(e) {
    e.preventDefault()
    const selectedAssignableIds = map(
      e.detail.selectedOptions,
      fetchOptionValue,
    )
    if (!selectedAssignableIds) {
      return
    }
    this.applyFilter('assignable_ids', 'is', null)
    if (
      selectedAssignableIds.length > 0 &&
      !selectedAssignableIds.includes(true)
    ) {
      this.applyFilter('assignable_ids', 'is', selectedAssignableIds)
    }
  }

  removeAssignable(e) {
    const assignableId = document.getElementById('Skill_id')
    const dataset = e.target.dataset
    this.removeMultiSelectValues('assignable_ids', assignableId, dataset.value)
  }

  removeMultiSelectValues(filterName, elementId, value) {
    const selectedIds = map(elementId.selectedOptions, fetchOptionValue)
    if (!selectedIds) {
      return
    }
    if (selectedIds.length > 0) {
      const index = selectedIds.indexOf(value)
      if (index > -1) {
        selectedIds.splice(index, 1)
      }
      this.applyFilter(filterName, 'is', null)
      if (selectedIds.length > 0) {
        this.applyFilter(filterName, 'is', selectedIds)
      }
    }
  }

  selectResponsible(event) {
    const selectedResponsibleIds = map(
      event.detail.selectedOptions,
      fetchOptionValue,
    )
    if (!selectedResponsibleIds) {
      return
    }
    if (
      selectedResponsibleIds.length > 1 &&
      selectedResponsibleIds.includes(true)
    ) {
      // remove true element from array
      selectedResponsibleIds.splice(selectedResponsibleIds.indexOf(true), 1)
    }
    if (
      selectedResponsibleIds.length > 0 &&
      !selectedResponsibleIds.includes(true)
    ) {
      this.applyFilter('responsible_user_id', 'is', null)
      this.applyFilter('responsible_user_id', 'is', selectedResponsibleIds)
    } else {
      this.applyFilter('responsible_user_id', 'is', null)
    }
  }

  selectCreator(event) {
    const selectedCreatorId = map(
      event.detail.selectedOptions,
      fetchOptionValue,
    )
    if (!selectedCreatorId) {
      return
    }
    this.applyFilter('creator_id', 'is', null)
    if (selectedCreatorId.length > 0 && !selectedCreatorId.includes(true)) {
      this.applyFilter('creator_id', 'is', null)
      this.applyFilter('creator_id', 'is', selectedCreatorId.pop())
    }
  }

  selectOwnAssigned(event) {
    event.preventDefault()
    const ownAssigned = event.currentTarget.dataset.userId
    const statuses = event.currentTarget.dataset.statusIds.split(',')
    if (statuses.length > 0) {
      statuses.forEach((status) => {
        this.applyFilter('ticket_status_id', 'is', status.toString())
      })
    }
    if (this.hasCurrentViewConfigIdTarget) {
      this.currentViewConfigIdTarget.value = ''
    }
    if (this.hasTabActionTarget) {
      this.tabActionTarget.value = 'reset'
    }
    this.applyFilter('responsible_user_id', 'is', null)
    this.applyFilter('responsible_user_id', 'is', [ownAssigned])
  }

  closeOffsidebarMenu(event) {
    const filterButtonClick = !!document
      .querySelector('[data-value="filters"]')
      .contains(event.target)
    const outsideClick = !this.offsidebarTarget.contains(event.target)
    if (!filterButtonClick && outsideClick) {
      this.offsidebarTarget.classList.remove('offsidebar-open')
      document.body.classList.add('offsidebar-animation')
      document.body.classList.remove('offsidebar-open')
      setTimeout(() => {
        document.body.classList.remove('offsidebar-animation')
      }, 500)
      document.removeEventListener(
        'click',
        (event) => this.closeOffsidebarMenu(event),
        true,
      )
    }
  }

  openOffsidebarMenu(e) {
    e.preventDefault()
    if (this.hasCurrentFilterTab) {
      this.currentFilterTabTarget.value = ''
    }
    const tabCurrentData = e.currentTarget.attributes['data-value']
    document
      .querySelector('.offsidebar-shadow')
      .addEventListener(
        'click',
        (event) => this.closeOffsidebarMenu(event),
        true,
      )
    this.offsidebarTarget.classList.toggle('offsidebar-open')
    document.body.classList.toggle('offsidebar-open')
    document.body.classList.add('offsidebar-animation')
    setTimeout(() => {
      document.body.classList.remove('offsidebar-animation')
    }, 500)
    if (!tabCurrentData) {
      return
    }
    this.switchSearchFiltersTab(e, tabCurrentData['value'])
  }

  switchSearchFiltersTab(e, tab) {
    if (!tab) {
      e.preventDefault()
      const tabCurrentData = e.currentTarget.attributes['data-value']
      tab = tabCurrentData['value']
    }
    if (this.hasCurrentFilterTab) {
      this.currentFilterTabTarget.value = tab
    }
    switch (tab) {
      case 'filters':
        this.toggleFiltersTabs()
        break
      case 'fields':
        this.toggleFieldsTabs()
        break
      case 'tabs':
        this.toggleTabsTabs()
        break
      case 'skills':
        this.toggleSkillsTabs()
        break
    }
  }

  toggleTabsTabs() {
    this.subMenuTabsTarget.classList.add('active')
    this.subMenuTabsContainerTarget.classList.add(...['show', 'active'])
    this.removeFilters()
    this.removeSubMenuFields()
    this.removeSubMenuFiltersContainerTarget()
    this.removeSubMenuFieldsContainer()
    this.removeSkills()
    this.removeSubMenuSkillsContainerTarget()
  }

  toggleFieldsTabs() {
    this.subMenuFieldsTarget.classList.add('active')
    this.subMenuFieldsContainerTarget.classList.add(...['show', 'active'])
    this.removeFilters()
    this.removeSubMenuTabs()
    this.removeSubMenuFiltersContainerTarget()
    this.removeSubMenuTabsContainer()
    this.removeSkills()
    this.removeSubMenuSkillsContainerTarget()
  }

  toggleFiltersTabs() {
    this.subMenuFiltersTarget.classList.add('active')
    this.subMenuFiltersContainerTarget.classList.add(...['show', 'active'])
    this.removeSubMenuFields()
    this.removeSubMenuTabs()
    this.removeSubMenuTabsContainer()
    this.removeSubMenuFieldsContainer()
    this.removeSkills()
    this.removeSubMenuSkillsContainerTarget()
  }

  toggleSkillsTabs() {
    this.subMenuSkillsTarget.classList.add('active')
    this.subMenuSkillsContainerTarget.classList.add(...['show', 'active'])
    this.removeFilters()
    this.removeSubMenuFiltersContainerTarget()
    this.removeSubMenuFields()
    this.removeSubMenuTabs()
    this.removeSubMenuTabsContainer()
    this.removeSubMenuFieldsContainer()
  }

  removeSkills() {
    if (this.hasSubMenuSkillsTarget) {
      this.subMenuSkillsTarget.classList.remove('active')
    }
  }

  removeSubMenuSkillsContainerTarget() {
    if (this.hasSubMenuSkillsContainerTarget) {
      this.subMenuSkillsContainerTarget.classList.remove(...['show', 'active'])
    }
  }

  removeFilters() {
    if (this.hasSubMenuFiltersTarget) {
      this.subMenuFiltersTarget.classList.remove('active')
    }
  }

  removeSubMenuFiltersContainerTarget() {
    if (this.hasSubMenuFiltersContainerTarget) {
      this.subMenuFiltersContainerTarget.classList.remove(...['show', 'active'])
    }
  }
  removeSubMenuFields() {
    if (this.hasSubMenuFieldsTarget) {
      this.subMenuFieldsTarget.classList.remove('active')
    }
  }

  removeSubMenuFieldsContainer() {
    if (this.hasSubMenuFieldsContainerTarget) {
      this.subMenuFieldsContainerTarget.classList.remove(...['show', 'active'])
    }
  }
  removeSubMenuTabs() {
    if (this.hasSubMenuTabsTarget) {
      this.subMenuTabsTarget.classList.remove('active')
    }
  }

  removeSubMenuTabsContainer() {
    if (this.hasSubMenuTabsContainerTarget) {
      this.subMenuTabsContainerTarget.classList.remove(...['show', 'active'])
    }
  }

  applyFilter(name, operation, value) {
    if (!value) {
      delete this.filters[name]
    } else {
      this.addToFilters(name, operation, value)
    }
    this.search()
  }

  addToFilters(name, operation, value) {
    const filter = this.filters[name]
    if (!filter) {
      this.filters[name] = { operation: operation, value: [value], type: name }
    } else if (
      [
        'start_date',
        'end_date',
        'created_at_start_date',
        'created_at_end_date',
      ].includes(name)
    ) {
      filter['value'] = value
    } else {
      if (filter['value'].indexOf(value) >= 0) {
        filter['value'] = filter['value'].filter(
          function(arValue, index, arr) {
            return arValue !== value
          },
        )
        if (filter['value'].length === 0) {
          delete this.filters[name]
        }
      } else {
        filter['value'].push(value)
      }
    }
  }

  serializeFilters() {
    let missingSortValue = ''
    let myTeamValue = ''
    if (this.hasMissingSortTarget) {
      missingSortValue = this.missingSortTarget.value
    }
    if (this.hasMyTeamTarget) {
      myTeamValue = this.myTeamTarget.value
    }
    if (this.columnFilters && Object.keys(this.columnFilters).length === 0) {
      this.columnFilters = null
    }
    return this.escapeString(
      JSON.stringify({
        filters: this.filters,
        column_filters: this.columnFilters,
        sort: this.sort,
        q: this.q,
        missing_sort: missingSortValue,
        my_team: myTeamValue,
      }),
    )
  }

  escapeString(str) {
    return window.btoa(unescape(encodeURIComponent(str)))
  }

  serializeColumns() {
    if (this.hasColumnFieldTarget && this.tabActionTarget.value == 'create') {
      this.setSelectedColumns()
    }
    return this.escapeString(JSON.stringify(this.columns))
  }

  setSelectedColumns() {
    this.columns = {}
    this.columnFieldTargets.forEach((target) => {
      if (target.checked) {
        if (target.dataset['value']) {
          this.columns[target.dataset['value']] = target.dataset['order']
        }
      }
    })
  }

  desirialize(value) {
    if (!value || value == '') {
      return
    }
    try {
      const decoded = this.unescape(value)
      const parsed = JSON.parse(decoded)
      return parsed
    } catch (e) {
      return {}
    }
  }

  unescape(str) {
    return decodeURIComponent(escape(atob(str)))
  }

  updateOrCreateConfig(e) {
    this.nameConfigTarget.value = this.configNameInputTarget.value
    this.setTabAction(e)
    this.search()
  }
  updateConfig(e) {
    this.updateIdConfigTarget.value = e.detail.id
    this.updateNameConfigTarget.value = e.detail.payload.name
    if (this.hasTabActionTarget) {
      this.tabActionTarget.value = 'update'
    }
    this.search()
  }

  deleteConfig(e) {
    this.setTabAction(e)
    this.deleteConfigTarget.value = e.currentTarget.dataset['value']
    this.search()
  }

  selectStartDate(e) {
    const startDate = e.target.value
    this.applyFilter('start_date', 'gte', startDate)
  }

  selectEndDate(e) {
    const endDate = e.target.value
    this.applyFilter('end_date', 'lte', endDate)
  }

  selectCreatedAtStartDate(e) {
    const startDate = e.target.value
    this.applyFilter('created_at_start_date', 'gte', startDate)
  }

  selectCreatedAtEndDate(e) {
    const endDate = e.target.value
    this.applyFilter('created_at_end_date', 'lte', endDate)
  }

  resetTab(e) {
    this.resetFilters()
    this.columns = {}
    this.columnFilters = {}
    if (this.hasCurrentViewConfigIdTarget) {
      this.currentViewConfigIdTarget.value = ''
    }
    this.setTabAction(e)
    if (this.hasMyTeamTarget) {
      this.myTeamTarget.value = false
      this.myTeam = ''
    }
    this.search()
  }

  resetColumnFilters() {
    this.columnFilters = {}
    this.columnFilters['status'] = { value: ['active'], type: 'multi' }
  }

  setTabAction(event) {
    if (this.hasTabActionTarget) {
      this.tabActionTarget.value = event.currentTarget.dataset['tabAction']
    }
  }

  search() {
    this.searchSelectService.diselectAll()
    clearTimeout(this.searchTimeout)
    this.searchTimeout = setTimeout(() => {
      if (this.hasPageNumberTarget) {
        this.pageNumberTarget.value = 1
      }
      this.searchFormTarget.classList.add('whirl', 'double-up')
      this.setTargetsValues()
      if (this.hasConfigNameInputTarget) {
        this.configNameInputTarget.value = ''
      }
      this.performSearch()
    }, TIMEOUT)
  }

  performSearch() {
    if (this.searchMethodValue == 'turbo') {
      this.turboSearch()
    } else {
      this.jsSearch()
    }
  }

  jsSearch() {
    Rails.fire(this.searchFormTarget, 'submit')
  }

  turboSearch() {
    this.searchFormTarget.requestSubmit()
  }

  switchItemView(e) {
    window.location = e.currentTarget.value
  }

  setTargetsValues() {
    const filtersHash = this.serializeFilters()
    const columnsHash = this.serializeColumns()
    if (
      filtersHash.length > 0 ||
      Object.keys(this.sort).length > 0 ||
      this.q !== '' ||
      this.myTeam !== ''
    ) {
      this.searchFiltersTarget.value = filtersHash
    } else {
      this.searchFiltersTarget.value = ''
    }
    if (this.hasColumnsTarget) {
      if (Object.keys(this.columns).length > 0) {
        this.columnsTarget.value = columnsHash
      } else {
        this.columnsTarget.value = ''
      }
    }
  }

  setUpdateTableHeaderFlag(flag) {
    if (this.hasUpdateTableHeaderFlagTarget) {
      this.updateTableHeaderFlagTarget.value = flag
    }
  }

  updateSubMenuFiltersContainerFlag(flag) {
    if (this.hasSubMenuFiltersContainerFlagTarget) {
      this.subMenuFiltersContainerFlagTarget.value = flag
    }
  }

  scrollTableToTop() {
    document.getElementById('table-sticky-headers').scrollTo(0, 0)
  }
}

