import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['deltaWrapper', 'deltaInput', 'commentField', 'movementsForm']

  static values = {
    maxQuantity: Number,
    notificationTextKey: String,
    notificationType: String,
    tabNavigationId: String,
  }

  connect() {
    this.showNotification()
    this.clickTab()
  }

  clickTab() {
    if (this.tabNavigationIdValue) {
      document.getElementById(this.tabNavigationIdValue).click()
    }
  }

  showNotification() {
    if (this.notificationTextKeyValue) {
      $.notify(window.I18n.t(this.notificationTextKeyValue), this.notificationTypeValue)
    }
  }

  stockItemAssigned(e) {
    e.stopPropagation()
    $.notify(window.I18n.t('inventory.stock_item_activities.checkout.success'), 'success')
  }

  changeQuontity(e) {
    const value = parseInt(e.currentTarget.value)
    this.deltaInputTarget.value = this.maxQuantityValue - value
    if (value === this.maxQuantityValue) {
      this.deltaWrapperTarget.classList.add('d-none')
    } else {
      this.deltaWrapperTarget.classList.remove('d-none')
    }
  }

  movementsChangePerPage() {
    Rails.fire(this.movementsFormTarget, 'submit')
  }

  addRemoveStockItems(e) {
    e.preventDefault()
    e.stopPropagation()
    const currentTarget = e.currentTarget
    const type = currentTarget.dataset['actionType']
    if (!document.getElementById('stock_item_activity_quantity').checkValidity()) {
      return document.getElementById('stock_item_activity_quantity').reportValidity()
    }
    if (type === 'remove_from_stock') {
      if (!this.commentFieldTarget.checkValidity()) {
        return this.commentFieldTarget.reportValidity()
      }
    }
    document.getElementById('stock_item_activity_action_type').value = type
    Rails.fire(document.getElementById('stock_item_activity_form'), 'submit')
  }

  validateInteger(e) {
    if (['+', '-'].includes(e.key)) {
      e.preventDefault()
    }
  }
}
