import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="assign-item"
export default class extends Controller {
  static targets = ['plannedCheckoutDate', 'plannedCheckinDate']

  static values = {
    itemType: String,
  }

  enableScheduleButtons() {
    const plannedCheckoutDate = new Date(this.plannedCheckoutDateTarget.value)
    const plannedCheckinDate = new Date(this.plannedCheckinDateTarget.value)
    if (!isNaN(plannedCheckoutDate.getTime()) && !isNaN(plannedCheckinDate.getTime())) {
      document.querySelectorAll('.tw-pointer-events-none').forEach((assignButton) => {
        assignButton.classList.remove('tw-pointer-events-none')
      })
    }
  }
  scheduleItemToResource(e) {
    const itemId = e.currentTarget.dataset['itemId']
    const resourceId = e.currentTarget.dataset['resourceId']
    const resourceType = e.currentTarget.dataset['resourceType']
    const assignedToResource = e.currentTarget.dataset['assignedToResource']
    const checkOutDate = new Date(this.plannedCheckoutDateTarget.value)
    const checkInDate = new Date(this.plannedCheckinDateTarget.value)
    const startDate = checkOutDate.toLocaleDateString()
    const startTime = checkOutDate.toLocaleTimeString()
    const plannedCheckInAt = checkInDate.toLocaleDateString()
    const plannedCheckInAtTime = checkInDate.toLocaleTimeString()

    // eslint-disable-next-line max-len
    const href = `/items/${itemId}/checkoutable_items/fast_checkout_to_resource?resource_type=${resourceType}&resource_id=${resourceId}&assigned_to_resource=${assignedToResource}&checkoutable_item[start_date]=${startDate}&checkoutable_item[start_time]=${startTime}&checkoutable_item[planned_check_in_at]=${plannedCheckInAt}&checkoutable_item[planned_check_in_at_time]=${plannedCheckInAtTime}`
    fetch(href, {
      method: 'post',
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
        'X-CSRF-Token': document.querySelectorAll('[name="csrf-token"]')[0].content,
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }
}
