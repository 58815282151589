import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'containerId',
    'searchForm'
  ]
  selectContainer(e, params){
    const paramValue = event.params
    if (paramValue.id) {
      this.containerIdTarget.value = paramValue.id
    }
    else {
      this.containerIdTarget.value = ''
    }
    this.searchFormTarget.requestSubmit()
  }
}
